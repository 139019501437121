import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  Container,
  Header as SUIHeader,
  Grid,
  Button as SUIButton,
  Image,
} from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TextContainer from "../components/text-container"
import "./contact-us.css"

const GridRow = styled(Grid.Row)`
  @media only screen and (min-width: 1200px) {
    padding: 4em 4em 0em 4em !important;
  }

  @media only screen and (max-width: 1119px) {
    padding: 2em 2em 0em 2em !important;
  }
`

const GridColumn = styled(Grid.Column)`
  background: #024b6f;
  margin: 1em;
  padding: 0px !important;

  p,
  a {
    color: white;
  }

  a {
    padding: 4em 0em !important;
    display: block;
    &:hover {
      color: white;
    }
  }
`

const Button = styled.a`
  width: 100%;
  padding: 2em 0em !important;
  margin-top: 2em !important;
  text-transform: uppercase !important;
  background: #d7203d !important;
  color: white !important;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Contact Us" keywords={[`host family english`, `hfe`]} />
    <Hero
      title="CONTACT US"
      dimmed
      img="https://res.cloudinary.com/mystay-international/image/upload/v1555992224/Host%20Family%20English/iStock-470418597_super-e1522982098187-1024x220.jpg"
    />
    <Grid
      stackable
      style={{
        marginLeft: "0em",
        marginRight: "0em",
      }}
    >
      <GridRow>
        <Grid.Column>
          <h2 style={{ textAlign: "center", marginBottom: "1em" }}>
            Enquire Now
          </h2>
        </Grid.Column>
      </GridRow>
      <Grid.Row centered>
        <GridColumn width="5" textAlign="center" style={{ marginTop: "1em" }}>
          <a href="/contact-us/business-enquiries">
            I AM AN EDUCATION PROVIDER OR AN AGENT
          </a>
        </GridColumn>
        <GridColumn width="5" textAlign="center" style={{ marginTop: "1em" }}>
          <a href="/contact-us/host-enquiries">I AM A HOST</a>
        </GridColumn>
      </Grid.Row>
      <Grid.Row centered>
        <GridColumn width="5" textAlign="center" style={{ marginTop: "1em" }}>
          <a href="/contact-us/other-enquiries">
            OTHER (E.G. GOVERNMENT, MEDIA OR INDUSTRY)
          </a>
        </GridColumn>
      </Grid.Row>
    </Grid>
  </Layout>
)

export default IndexPage
